import React from 'react';

export default class RootContainer extends React.Component {
  onAppBodyClicked(oEvent) {
    console.log("App body clicked");
  }
  render() {
    return null;
  }
}
