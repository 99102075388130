

import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
// include styles
import 'rodal/lib/rodal.css';
import './index.scss';

class RatingStar extends React.Component {
  render() {
    return (
      <StarRatingComponent
        {...this.props}
        onStarClick={this.props.onStarClick}
      />
    )
  }
}

export default RatingStar;

//  <StarRatingComponent
//     name={String} /* name of the radio input, it is required */
//     value={Number} /* number of selected icon (`0` - none, `1` - first) */
//     starCount={Number} /* number of icons in rating, default `5` */
//     onStarClick={Function(nextValue, prevValue, name)} /* on icon click handler */
//     onStarHover={Function(nextValue, prevValue, name)} /* on icon hover handler */
//     onStarHoverOut={Function(nextValue, prevValue, name)} /* on icon hover out handler */
//     renderStarIcon={Function(nextValue, prevValue, name)} /* it should return string or react component */
//     renderStarIconHalf={Function(nextValue, prevValue, name)} /* it should return string or react component */
//     starColor={String} /* color of selected icons, default `#ffb400` */
//     emptyStarColor={String} /* color of non-selected icons, default `#333` */
//     editing={Boolean} /* is component available for editing, default `true` */
// />