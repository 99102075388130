import React, { Component } from 'react';
import oResourceBundle from "app/i18n/";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionTypes from 'app/store/action/';
import * as common from 'app/utility/common';
import * as CONSTANTS from 'app/AppConfig/constants';
import { toast } from "core/components/Toaster/";
import { Link } from 'react-router-dom';
import Spinner from "core/components/Spinner/";
import { isUserLoggedIn, isUserSubscribed } from "app/utility/common";
import { CleverTap_privacy } from 'core/CleverTap'
import RightTick from "../../../resources/assets/Symbol.png"

class ManageCookies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEssential: false,
            showPerformance: false,
            showAdvertising: false,
            EssentialToggle: true,
            PerformanceToggle: true,
            AdvertisingToggle: false,
            GoogleAnalytics: true,
            Firebase: false,
            AppsFlyer: false,
            Aiqua: false,
            GoogleAds: false,
            FacebookAds: false,
            cleverTap: true,
            GDPR_payment_data: '',
            isDataLoaded: false
        };
    }

    componentDidMount() {
        var pathArray = window.location.href.split('/');
        var path = pathArray[pathArray.length - 1];
        // this.props.startLoader();
        if (path != 'Manage-Cookies') {
            // this.props.stopLoader();
            this.setState({
                isDataLoaded: true
            })
        }
        let Lang = this.props.locale == 'en' ? 'EN' : 'AR'
        // Get Payment Gatway Details for Essentials
        this.props.fnGDPR_PaymentGateWay_Lists(this.props.countryCode, Lang);
        // Get Details for Essentials Performance, Analytics & Advertising
        this.props.fnFetchUserDetails(null, null, true);

        this.setState({
            showEssential: this.props.MoreToggle,
            showPerformance: this.props.MoreToggle,
            showAdvertising: this.props.MoreToggle,
        })
    }
    componentWillReceiveProps(props) {
        var pathArray = window.location.href.split('/');
        var path = pathArray[pathArray.length - 1];
        if (props && props.oUserAccountDetails) {
            this.setState({
                isDataLoaded: true
            })
            if (path == 'Manage-Cookies') {
                this.setState({
                    PerformanceToggle: props.oUserAccountDetails ? props.oUserAccountDetails.performance : "",
                    AdvertisingToggle: props.oUserAccountDetails ? props.oUserAccountDetails.advertising : "",
                    GoogleAnalytics: props.oUserAccountDetails ? props.oUserAccountDetails.googleAnalytics : "",
                    Firebase: props.oUserAccountDetails ? props.oUserAccountDetails.firebase : "",
                    AppsFlyer: props.oUserAccountDetails ? props.oUserAccountDetails.appFlyer : "",
                    Aiqua: props.oUserAccountDetails ? props.oUserAccountDetails.aique : "",
                    GoogleAds: props.oUserAccountDetails ? props.oUserAccountDetails.googleAds : "",
                    cleverTap: props.oUserAccountDetails ? props.oUserAccountDetails.cleverTap : "",
                    FacebookAds:props.oUserAccountDetails?props.oUserAccountDetails.facebookAds : "",
                })
            }
        }
    }
    componentDidUpdate(prevProps) {

        let Lang = this.props.locale == 'en' ? 'EN' : 'AR'

        if (this.props.countryCode !== prevProps.countryCode || this.props.locale !== prevProps.locale) {
            this.props.fnGDPR_PaymentGateWay_Lists(this.props.countryCode, Lang);
        }
    }
    manageEssentialInfo = () => {
        this.setState({
            showEssential: !this.state.showEssential
        });
    };
    managePerformanceAnalyticsInfo = () => {
        this.setState({
            showPerformance: !this.state.showPerformance
        });
    };
    manageAdvertisingInfo = () => {
        this.setState({
            showAdvertising: !this.state.showAdvertising
        });
    };
    handleEssentialToggle = () => {
        this.setState({
            EssentialToggle: !this.state.EssentialToggle
        });
    };
    handlePerformanceToggle = () => {
        this.setState({
            PerformanceToggle: !this.state.PerformanceToggle,
            GoogleAnalytics: !this.state.PerformanceToggle,          
        });
    };
    handleAdvertisingToggle = () => {
        this.setState({
            AdvertisingToggle: !this.state.AdvertisingToggle,
            GoogleAds: !this.state.AdvertisingToggle,
            cleverTap: !this.state.AdvertisingToggle,
            FacebookAds: !this.state.AdvertisingToggle
        });
    };
   
    checkAllPerformanceToogle = (GoogleAnalytics) => {
        let PerformanceToogleButton = this.state.PerformanceToggle

        if (GoogleAnalytics) {
            PerformanceToogleButton = true
        } else if (!GoogleAnalytics) {
            PerformanceToogleButton = false
        } else {
            PerformanceToogleButton = this.state.PerformanceToggle
        }

        return PerformanceToogleButton
    }
    handleAcceptPreferences = () => {
        const data2 = {
            GoogleAds: true,
            FacebookAds: true,
        }
    }
    checkAllAdvertisingToogle = (GoogleAds, cleverTap,FacebookAds) => {
        let AdvertisingToogleButton = this.state.AdvertisingToggle

        if (GoogleAds || cleverTap || FacebookAds) {
            AdvertisingToogleButton = true
        } else if (!GoogleAds && !cleverTap && !FacebookAds) {
            AdvertisingToogleButton = false
        } else {
            AdvertisingToogleButton = this.state.AdvertisingToggle
        }

        return AdvertisingToogleButton
    }

      handleGoogleAnalyticsToggle = (toogleActive) => {
        this.setState({
            GoogleAnalytics: !this.state.GoogleAnalytics,
            PerformanceToggle: this.checkAllPerformanceToogle(toogleActive),
        });
    }
    handleGoogleAdsToggle = (toogleActive) => {
        const updatedGoogleAdsValue = !this.state.GoogleAds  ;
        localStorage.setItem("Google Ads", updatedGoogleAdsValue);   
      
        this.setState({
            GoogleAds: updatedGoogleAdsValue,
            AdvertisingToggle: this.checkAllAdvertisingToogle(toogleActive, this.state.cleverTap)
        });
            }
    handleCleverTapToggle = (toogleActive) => {
        this.setState({
            cleverTap: !this.state.cleverTap,
            AdvertisingToggle: this.checkAllAdvertisingToogle(this.state.GoogleAds, toogleActive),
        });
    }
    handleFacebookAdsToggle = (toogleActive) => {
        const updatedFacebookAdsValue = !this.state.FacebookAds;
        localStorage.setItem("Facebook Ads", updatedFacebookAdsValue);  
     
        this.setState({
            FacebookAds: updatedFacebookAdsValue,
            AdvertisingToggle: this.checkAllAdvertisingToogle(toogleActive, this.state.cleverTap)
        });
        }
    CookieReturnBack = () => {
        var pathArray = window.location.href.split('/');
        var path = pathArray[pathArray.length - 1];

        if (path == 'Manage-Cookies') {
            this.props.history.push(`/${this.props.locale}/settings`);
            // window.history.back()
        } else {
            this.props.openManageCookiesSettings()
        }
    }
 
    CookiesPolicyOk = () => {
        var pathArray = window.location.href.split('/');
        var path = pathArray[pathArray.length - 1];
        let userInfo = this.props.oUserAccountDetails ? this.props.oUserAccountDetails : null
        let GDPR_USER_DATA = {
            fname: userInfo ? userInfo.firstName : "",
            lname: userInfo ? userInfo.lastName : "",
            email: userInfo ? userInfo.email : "",
            newsletter: userInfo ? userInfo.newslettersEnabled : "",
            promotions: userInfo ? userInfo.promotionsEnabled : "",
            country: userInfo ? userInfo.countryName : "",
            selectedCountryCode: userInfo ? userInfo.countryId : "",
            language: userInfo ? userInfo.languageName : "",
            selectedLanguageCode: userInfo ? userInfo.languageId : "",
            newsletter1: userInfo ? userInfo.privacyPolicy : "",
            newsletter2: userInfo ? userInfo.isAdult : "",
            newsletter3: userInfo ? userInfo.isRecommend : "",
            firebase: userInfo ? userInfo.firebase : "",
            appFlyer: userInfo ? userInfo.appFlyer : "",
            aique: userInfo ? userInfo.aique : "",
            isGdprAccepted: true,
            performance: this.state.PerformanceToggle,
            advertising: this.state.AdvertisingToggle,
            googleAnalytics: this.state.GoogleAnalytics,
            cleverTap: this.state.cleverTap,
            googleAds: this.state.GoogleAds ,
            facebookAds: this.state.FacebookAds 
        }
        let data = {
            performance: this.state.PerformanceToggle,
            advertising: this.state.AdvertisingToggle,
            googleAnalytics: this.state.GoogleAnalytics,
            cleverTap: this.state.cleverTap,
            googleAds: this.state.GoogleAds,
            facebookAds: this.state.FacebookAds,            
        }
        if (path == 'Manage-Cookies') {
            this.props.handleUpdateAccount(
                GDPR_USER_DATA,
                () => {
                    toast.dismiss();
                    toast.success(oResourceBundle.cookie_update_success, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                },
                oError => {
                    if (oError) {
                        toast.dismiss();
                        toast.success(oError.description, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.dismiss();
                        toast.success(oResourceBundle.something_went_wrong, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }
            );
            common.setGDPRCookie('cookies_accepted', "true")
            common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.INFINITE_COOKIE_TIME);        

            // Clevertap user Privacy 
            CleverTap_privacy()
            this.props.history.push(`/${this.props.locale}/settings`);
        } else {

            if (isUserLoggedIn()) {
                this.props.handleUpdateAccount(
                    GDPR_USER_DATA,
                    () => {
                        //Data updated successfully
                        toast.dismiss();
                        toast.success(oResourceBundle.cookie_update_success, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    },
                    oError => {
                        this.setState({ bEnableUpdateBtn: true });
                        if (oError) {
                            toast.dismiss();
                            toast.success(oError.description, {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        } else {
                            toast.dismiss();
                            toast.success(oResourceBundle.something_went_wrong, {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    }
                );
                // data.expiresTime = CONSTANTS.INFINITE_COOKIE_TIME
                common.setGDPRCookie('cookies_accepted', "true")
                common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.INFINITE_COOKIE_TIME);
                CleverTap_privacy()             
            } else {
                // data.expiresTime = CONSTANTS.GDPRCookieExpires
                common.setGDPRCookie('cookies_accepted', "true")
                common.setGDPRCookie('GDPR_Cookies', data, CONSTANTS.GDPRCookieExpires);
                CleverTap_privacy()               
            }
            this.props.openManageCookiesSettings()
        }
    }
    render() {
        var pathArray = window.location.href.split('/');
        var path = pathArray[pathArray.length - 1];
        let EssentialData = [ oResourceBundle.Apple_sign_in, oResourceBundle.Credit_Card_Payment_Adyen, oResourceBundle.Mobile_Payment_TPAY,
            oResourceBundle.Mobile_Payment_Etisalat_UAE, oResourceBundle.Google_Analytics,oResourceBundle.cleverTap]
        return (
            <div className="latest-cookie-block" style={{ maxHeight: path == 'Manage-Cookies' ? "inherit" : '' }} >
                {
                    this.state.isDataLoaded ?
                        <section>
                            <p className="mb-5" >
                                <b>{oResourceBundle.ManageYourCookies}</b>
                            </p>
                            <p>
                                {oResourceBundle.GDPR_Manage_Cookies_2}
                            </p>
                            <p className="cookies-text">
                                {oResourceBundle.GDPR_Manage_Cookies_3} <Link
                                    to={`/${this.props.locale}/static/privacy-policy-${this.props.locale
                                        }`}
                                    aria-label={oResourceBundle.Privacy__Policy}
                                >
                                    <span>{oResourceBundle.Privacy__Policy}</span>
                                </Link> 
                            </p>
                            <div className="text-toggle-block">
                                <div className="content-text-block">
                                    <p className="mb-5">
                                        <b>{oResourceBundle.Essential}</b>
                                    </p>
                                    <p>{oResourceBundle.Essential_cookie_1}</p>
                                    <button className="d-flex align-items-center" onClick={this.manageEssentialInfo}>
                                        {this.state.showEssential ? oResourceBundle.Less : oResourceBundle.More}
                                        <div className={this.state.showEssential ? 'ml-5 arrow-up' : 'ml-5 arrow-down'} />
                                    </button>
                                   
                                </div>
                                <div className="toggle-btn">
                                    <div className="toogle-custom-block">
                                        <div className="ToggleSwitch active">
                                            <div className="toggle-text">
                                                {oResourceBundle.Always_On}
                                            </div>
                                            <div className={this.state.EssentialToggle ? 'knob active' : 'knob'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.showEssential ? (
                                <div>
                                    <div className="dropdown-data">
                                        <ul>                                           
                                           
                                            {
                                                EssentialData && EssentialData.map((item) => {
                                                    return <li>
                                                        <div className="data-item">
                                                            <span>{item}</span>

                                                            <div className="toggle-btn">
                                                                <div className="toogle-custom-block">
                                                                    <div>
                                                                        <img src={RightTick}></img>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                })
                                            }                                        

                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                       {this.props.isMENARegion ?    <div className="text-toggle-block">
                                <div className="content-text-block">
                                    <p className="mb-5">
                                        <b>{oResourceBundle.Advertising}</b>
                                    </p>
                                    <p>
                                        {oResourceBundle.Advertising_Cookie_1}
                                    </p>
                                    <button className="d-flex align-items-center" onClick={this.manageAdvertisingInfo}>
                                        {this.state.showAdvertising ? oResourceBundle.Less : oResourceBundle.More}
                                        <div className={this.state.showAdvertising ? 'ml-5 arrow-up' : 'ml-5 arrow-down'} />
                                    </button>                         

                                </div>
                                <div className="toggle-btn">
                                    <div className="toogle-custom-block">
                                        <div
                                            onClick={this.handleAdvertisingToggle}
                                            className={
                                                (this.state.GoogleAds ||this.state.FacebookAds ) ? 'ToggleSwitch active' : 'ToggleSwitch'
                                            }
                                        >
                                            <div className="toggle-text">
                                                {(this.state.GoogleAds ||this.state.FacebookAds  )  ? oResourceBundle.On : oResourceBundle.Off}
                                            </div>
                                            <div className={ (this.state.GoogleAds ||this.state.FacebookAds )   ? 'knob active' : 'knob'} />
                                        </div>
                                    </div>
                                </div>
                            </div> :""}

                            {this.state.showAdvertising && this.props.isMENARegion ? (
                                <div>
                                    <div className="dropdown-data">
                                        <ul>                                       
                                            <li>
                                                <div className="data-item">
                                                    <span>{oResourceBundle.Google_Ads}</span>

                                                    <div className="toggle-btn">
                                                        <div className="toogle-custom-block">
                                                            <div onClick={() => this.handleGoogleAdsToggle(!this.state.GoogleAds)} className={ this.state.GoogleAds  ? 'ToggleSwitch active' : 'ToggleSwitch'}>
                                                                <div className="toggle-text">
                                                                    { this.state.GoogleAds ? oResourceBundle.On : oResourceBundle.Off}
                                                                </div>
                                                                <div
                                                                    className={
                                                                        this.state.GoogleAds ? 'knob active' : 'knob'
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>                                         
                                            <li>
                                        <div className="data-item">
                                            <span>{oResourceBundle.Facebook_Ads}</span>

                                            <div className="toggle-btn">
                                                <div className="toogle-custom-block">
                                                    <div  onClick={() => this.handleFacebookAdsToggle(!this.state.FacebookAds)} className={ this.state.FacebookAds ? 'ToggleSwitch active' : 'ToggleSwitch'}>
                                                  <div div className="toggle-text">
                                                                    { this.state.FacebookAds? oResourceBundle.On : oResourceBundle.Off}
                                                                </div>
                                                        <div
                                                            className={
                                                                this.state.FacebookAds? 'knob active' : 'knob'
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </li>

                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )} 

                            <div className="cookie-btn-group">
                                <button className="new-stroke-btn" onClick={this.CookieReturnBack}>
                                    {' '}
                                    {oResourceBundle.btn_cancel}{' '}
                                </button>
                                <button className="new-orange-btn" style={{ padding: "0px 20px" }} onClick={this.CookiesPolicyOk}>
                                    {' '}
                                    {oResourceBundle.Save_my_settings}{' '}
                                </button>
                            </div>

                        </section> : <Spinner />}
            </div>

        );
    }
}



const mapStateToProps = (state) => {
    return {
        locale: state.locale,
        countryCode: state.sCountryCode,
        GDPRPaymentGatewaysList: state.aGDPRPaymentGatewaysList,
        oUserAccountDetails: state.oUserAccountDetails,
        isMENARegion: state.isMENARegion
    };
};

/**
 * Component Name - ManageCookies
 * method that maps state to props.
 * @param {Object} dispatch - dispatcher from store.
 * @return {Object} - dispatchers mapped to props
 */
const mapDispatchToProps = (dispatch) => {
    //dispatch action to redux store
    return {
        fnGDPR_PaymentGateWay_Lists: (sCountryCode, sLocale) => {
            dispatch(
                actionTypes.fnGDPR_PaymentGateWay_Lists(
                    sCountryCode,
                    sLocale
                )
            );
        },
        fnFetchUserDetails: (fnSuccess, fnFailed, bShouldDispatch) => {
            dispatch(
                actionTypes.fnFetchUserDetails(fnSuccess, fnFailed, bShouldDispatch)
            );
        },
        handleUpdateAccount: (currentStateValues, fnSuccess, fnFailed) => {
            dispatch(
                actionTypes.fnHandleUpdateAccount(
                    currentStateValues,
                    fnSuccess,
                    fnFailed
                )
            );
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageCookies));
