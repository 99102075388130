import React from "react";
import "./index.scss";

const Menu = React.memo(
  React.forwardRef((props, ref) => (
    <section
      ref={ref}
      className={
        "sidemenu" +
        (props.className ? " " + props.className : "") +
        (props.show ? " show" : "") + // Add 'show' class if true
        (props.direction === "right" ? " right" : " left")
      }
    >
      {props.showCloseBtn ? (
        <span className="closebtn" onClick={props.closeButtonClick}>
          ×
        </span>
      ) : null}
      {props.children}
    </section>
  ))
);

export default Menu;
