export const ENABLE_CUSTOM_CONTROLS = true;
export const ENABLE_APP_MENU = true;
export const ENABLE_EMAIL_LOGIN = true;
export const ENABLE_VIDEO_ADVERTISEMENT = true;
export const ENABLE_BANNER_ADVERTISEMENT = true;
export const ENABLE_CHANGE_PASSWORD = true;
export const ENABLE_REFRESH_TOKEN = true;
export const ENABLE_SERIES_PLAYBUTTON_TO_PLAYER = true;
export const ENABLE_FORGOT_PASSWORD = true;
export const ENABLE_SEARCH = true;
export const ENABLE_SUBSCRIPTION = true;
export const ENABLE_COUNTRY_QUERY_PARAM = false;
export const UAT_API = false;
export const QA_API = false;
export const IS_EGYPT = false;
export const STAGE_API = false;
export const ENABLE_MUTED_AUTOPLAY = false;
export const FORCE_MENA_REGION = false;
export const IS_PAYMENT_QA = false;
export const IS_PAYMENT_STAGE = false;
export const ENABLE_CONTACT_US = true;
export const IS_PAYMENT_PROD = true;
export const FORCE_ALPHANUMERIC_PASSWORD = false;
export const ADD_TRIAL_BANNER = true;
export const ENABLE_TPAY = true;
export const ENABLE_COUPONS = true;
