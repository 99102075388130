import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "core/components/Button/";
import { Link } from "react-router-dom";
import * as features from "app/AppConfig/features";
import searchIcon from "app/resources/assets/header/ic-search.svg";
import mobileSearchIcon from "app/resources/assets/header/mobileSearchEn.svg";
import mobilesearchAr from "app/resources/assets/header/mobileSearchAr.svg";
import menuImage from "app/resources/assets/hamburger1.svg";
import CircleUser from "app/resources/assets/newslider/circleUser_icon.svg";
import oResourceBundle from "app/i18n/";
import HandlerContext from "app/views/Context/HandlerContext";
import { fnConstructContentURL } from "app/utility/common";
import LanguageButton from "app/views/components/LanguageButton/";
import * as common from "app/utility/common";
import * as CONSTANTS from 'app/AppConfig/constants';
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import "./index.scss";

class HeaderContentLeft extends React.PureComponent {
    static contextType = HandlerContext;

    constructor(props) {
        super(props);
        this.state = {
            MenuActive: false,
            showMobileSearch: false
        };
    }

    componentDidMount() {
        common.isUserSubscribed();
        // this.onMenuButtonSelected({ preventDefault: () => {}, stopPropagation: () => {} })
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.showSearchInput !== this.props.showSearchInput &&
            this.props.showSearchInput
        ) {
            this.refs["search-input"].focus();
        }

        if (this.props.show) {
            this.setState({
                MenuActive: true
            });
        } else {
            this.setState({
                MenuActive: false
            });
        }
    }

    subcriptionClick = (e) => {
        this.props.history.push(`/${this.props.locale}/${CONSTANTS.PLANS_DESCRIPTION}`);
    }

    onMenuButtonSelected = (e) => {
        this.props.onMenuButtonClick(e);
    }

    render() {
        const screenWidth = window.innerWidth;
const isTablet = screenWidth >= 768 && screenWidth <= 1024;
const isMobileView = screenWidth <= 767

        return (
            <React.Fragment>
                {/* {(isMobile && !isTablet) || (isTablet && isMobile) ? */}
                {isMobileView  ? 
    <div className="bottom-sticky" id="bottomSticky">
        <div className={this.props.locale === "en" ? "mobile-searchEn" : "mobile-searchAr"}>
            <img
                src={this.props.locale === "en" ? mobileSearchIcon : mobilesearchAr}
                alt=""
                onClick={this.props.onSearchIconClick}
            />
        </div>
        {this.props.showMobileSearch && (
            <div className="mobile-layout">
                <section className="search-form">
                    <input
                        type="text"
                        placeholder={oResourceBundle.search_placeholder}
                        maxLength="100"
                        autoComplete="off"
                        className={this.props.locale === "en" ? "search-input-mobile" : "search-input-mobileAr"}
                        onClick={(evt) => this.context.onSearchInputClicked(evt)}
                        aria-invalid="false"
                        ref="search-input"
                        onChange={features.ENABLE_SEARCH ? this.props.handleSearchInputText : null}
                        onKeyPress={this.props.keyPress}
                        onKeyUp={this.props.keyUp}
                        onKeyDown={this.props.keyDown}
                        value={this.props.userInputText}
                    />
                    <img src={searchIcon} alt="Search Icon" className={this.props.locale === "en" ? "search-icon-Mobile" : "search-icon-mobileAr"} />
                    {this.props.userSearchResponseList.length > 0 && (
                        <div className="select-box-container">
                            <div className="select-box" style={{ zIndex: "3" }}>
                                <div className="select-box-elements">
                                    {this.props.userSearchResponseList.map((ele, index) => (
                                        this.props.userSearchResponseList[0].id !== 0 ? (
                                            <Link
                                                aria-label={ele.title}
                                                aria-required="true"
                                                key={ele.id}
                                                onClick={() => this.props.onSearchIconClick()}
                                                to={`/${this.props.locale}${fnConstructContentURL(ele.content_type, ele)}`}
                                            >
                                                <div className="select-element">{ele.title}</div>
                                            </Link>
                                        ) : (
                                            <div className="select-element nodata" key={ele.id}>
                                                {ele.title}
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        )}
        <Button
            className={this.props.locale === "en" ? "menu-icon-mobile" : "menu-icon-mobileAr"}
            icon={menuImage}
            onClick={(e) => this.onMenuButtonSelected(e)}
            alt={oResourceBundle.btn_menu}
        />
    </div>
                    
                        :
                         <div
                            className={`search-bar ${this.props.showSearchInput ? "expand" : ""} ${this.props.userSearchResponseList.length > 0 ? 'hasResults' : ''}`}
                            tabIndex="0"
                            role="search"
                        >
                            <Button
                                className={this.props.locale === "en" ? "search-icon left-search-icon" : "search-iconAR  rightAr-search-icon"}
                                icon={searchIcon}
                                onClick={this.props.onSearchButtonClick}
                                alt={oResourceBundle.search} />
                            <section className="search-form">
                                <input
                                    type="text"
                                    label={oResourceBundle.search_placeholder}
                                    maxLength="100"
                                    autoComplete="off"
                                    className={this.props.locale === "en" ? "search-input" : "search-inputAR"}
                                    onClick={evt => this.context.onSearchInputClicked(evt)}
                                    aria-invalid="false"
                                    ref="search-input"
                                    onChange={features.ENABLE_SEARCH ? this.props.handleSearchInputText : null}
                                    onKeyPress={this.props.keyPress}
                                    onKeyUp={this.props.keyUp}
                                    onKeyDown={this.props.keyDown}
                                    value={this.props.userInputText} />
                                {this.props.userSearchResponseList.length > 0 && (
                                    <div className="select-box-container">
                                        <div className="select-box">
                                            <div className="select-box-elements">
                                                {this.props.userSearchResponseList.map((ele, index) => {
                                                    return this.props.userSearchResponseList[0].id !== 0 ? (
                                                        <Link
                                                            aria-label={ele.title}
                                                            aria-required="true"
                                                            key={ele.id}
                                                            to={`/${this.props.locale}${fnConstructContentURL(
                                                                ele.content_type,
                                                                ele
                                                            )}`}
                                                        >
                                                            <div className="select-element">{ele.title}</div>
                                                        </Link>
                                                    ) : (
                                                        <div
                                                            className="select-element nodata"
                                                            key={ele.id}
                                                        >
                                                            {ele.title}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </section>
                        </div>
                }


                <LanguageButton
                    locale={this.props.locale}
                    onLanguageButtonCLick={this.props.onLanguageButtonCLick}
                />
                {features.ENABLE_SUBSCRIPTION &&
                    !this.props.isUserSubscribed &&
                    common.showSubscription(this.props.history.location.pathname) ? (
                    <Button
                        className="subscribe-btn"
                        onClick={this.context.onSubscribeButtonClick}
                    >
                        <span style={{ "font-weight": "initial" }} >{oResourceBundle.subscribe_landing_text}</span>
                    </Button>
                ) : null}
                {!isMobileView?
                <Button
                    className="menu-icon"
                    icon={CircleUser}
                    onClick={(e) => this.onMenuButtonSelected(e)}
                    alt={oResourceBundle.btn_menu}
                />
                 :""}  

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        userSearchResponseList: state.userSearchResponseList,
        isUserSubscribed: state.bIsUserSubscribed
    };
};

export default withRouter(
    connect(mapStateToProps)(HeaderContentLeft));