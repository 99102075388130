//Meta tag
export const WESITE = "website";
export const VIDEO_MOVIE = "video.movie";
export const OG_URL = 'og:url';
export const OG_TYPE = 'og:type';
export const OG_TITLE = 'og:title';
export const OG_DESCRIPTION = 'og:description';
export const OG_IMAGE = 'og:image';
export const DESCRIPTION = 'description';
export const TITLE = 'title';
export const WEYYAK = "Weyyak";
export const APPLE_ITUNES_APP = "apple-itunes-app";
export const APPLE_ITUNES_APP_ID = "app-id=1226514781";
export const GOOGLE_PLAY_APP = "google-play-app";
export const GOOGLE_PLAY_APP_ID = "app-id=com.tva.z5";
export const SWIPER_DEFAULT_ANIMATION_SPEED = 300;

export const OK_KEY = 13;
export const BACK_KEY = 8;
export const DELETE_KEY = 46;
