export const COUNTRIES = {
  en: [
    {code: 4, name: "Afghanistan"},
    {code: 248, name: "Åland Islands"},
    {code: 8, name: "Albania"},
    {code: 12, name: "Algeria"},
    {code: 16, name: "American Samoa"},
    {code: 20, name: "Andorra"},
    {code: 24, name: "Angola"},
    {code: 660, name: "Anguilla"},
    {code: 10, name: "Antarctica"},
    {code: 28, name: "Antigua and Barbuda"},
    {code: 32, name: "Argentina"},
    {code: 51, name: "Armenia"},
    {code: 533, name: "Aruba"},
    {code: 654, name: "Ascension and Tristan Da Cunha Saint Helena"},
    {code: 36, name: "Australia"},
    {code: 40, name: "Austria"},
    {code: 31, name: "Azerbaijan"},
    {code: 44, name: "Bahamas"},
    {code: 48, name: "Bahrain"},
    {code: 50, name: "Bangladesh"},
    {code: 52, name: "Barbados"},
    {code: 112, name: "Belarus"},
    {code: 56, name: "Belgium"},
    {code: 84, name: "Belize"},
    {code: 204, name: "Benin"},
    {code: 60, name: "Bermuda"},
    {code: 64, name: "Bhutan"},
    {code: 68, name: "Bolivia"},
    {code: 70, name: "Bosnia and Herzegowina"},
    {code: 72, name: "Botswana"},
    {code: 74, name: "Bouvet Island"},
    {code: 76, name: "Brazil"},
    {code: 86, name: "British Indian Ocean Territory"},
    {code: 96, name: "Brunei Darussalam"},
    {code: 100, name: "Bulgaria"},
    {code: 854, name: "Burkina Faso"},
    {code: 108, name: "Burundi"},
    {code: 132, name: "Cabo Verde"},
    {code: 116, name: "Cambodia"},
    {code: 120, name: "Cameroon"},
    {code: 124, name: "Canada"},
    {code: 136, name: "Cayman Islands"},
    {code: 140, name: "Central African Republic"},
    {code: 148, name: "Chad"},
    {code: 152, name: "Chile"},
    {code: 156, name: "China"},
    {code: 162, name: "Christmas Island"},
    {code: 166, name: "Cocos Islands"},
    {code: 170, name: "Colombia"},
    {code: 174, name: "Comoros"},
    {code: 178, name: "Congo"},
    {code: 184, name: "Cook Islands"},
    {code: 188, name: "Costa Rica"},
    {code: 384, name: "Côte d'Ivoire"},
    {code: 191, name: "Croatia"},
    {code: 192, name: "Cuba"},
    {code: 531, name: "Curaçao"},
    {code: 196, name: "Cyprus"},
    {code: 203, name: "Czechia"},
    {code: 408, name: "Democratic People's Republic of Korea"},
    {code: 208, name: "Denmark"},
    {code: 262, name: "Djibouti"},
    {code: 212, name: "Dominica"},
    {code: 214, name: "Dominican Republic"},
    {code: 218, name: "Ecuador"},
    {code: 818, name: "Egypt"},
    {code: 222, name: "El Salvador"},
    {code: 226, name: "Equatorial Guinea"},
    {code: 232, name: "Eritrea"},
    {code: 233, name: "Estonia"},
    {code: 231, name: "Ethiopia"},
    {code: 238, name: "Falkland Islands"},
    {code: 234, name: "Faroe Islands"},
    {code: 583, name: "Federated States of Micronesia"},
    {code: 242, name: "Fiji"},
    {code: 246, name: "Finland"},
    {code: 250, name: "France"},
    {code: 254, name: "French Guiana"},
    {code: 258, name: "French Polynesia"},
    {code: 260, name: "French Southern Territories"},
    {code: 266, name: "Gabon"},
    {code: 270, name: "Gambia"},
    {code: 268, name: "Georgia"},
    {code: 276, name: "Germany"},
    {code: 288, name: "Ghana"},
    {code: 292, name: "Gibraltar"},
    {code: 300, name: "Greece"},
    {code: 304, name: "Greenland"},
    {code: 308, name: "Grenada"},
    {code: 312, name: "Guadeloupe"},
    {code: 316, name: "Guam"},
    {code: 320, name: "Guatemala"},
    {code: 831, name: "Guernsey"},
    {code: 324, name: "Guinea"},
    {code: 624, name: "Guinea-Bissau"},
    {code: 328, name: "Guyana"},
    {code: 332, name: "Haiti"},
    {code: 334, name: "Heard and McDonald Islands"},
    {code: 336, name: "Holy See"},
    {code: 340, name: "Honduras"},
    {code: 344, name: "Hong Kong"},
    {code: 348, name: "Hungary"},
    {code: 352, name: "Iceland"},
    {code: 356, name: "India"},
    {code: 360, name: "Indonesia"},
    {code: 364, name: "Iran"},
    {code: 368, name: "Iraq"},
    {code: 372, name: "Ireland"},
    {code: 833, name: "Isle of Man"},
    {code: 376, name: "Israel"},
    {code: 380, name: "Italy"},
    {code: 388, name: "Jamaica"},
    {code: 392, name: "Japan"},
    {code: 832, name: "Jersey"},
    {code: 400, name: "Jordan"},
    {code: 398, name: "Kazakhstan"},
    {code: 404, name: "Kenya"},
    {code: 296, name: "Kiribati"},
    {code: 410, name: "Korea"},
    {code: 414, name: "Kuwait"},
    {code: 417, name: "Kyrgyzstan"},
    {code: 418, name: "Laos"},
    {code: 428, name: "Latvia"},
    {code: 422, name: "Lebanon"},
    {code: 426, name: "Lesotho"},
    {code: 430, name: "Liberia"},
    {code: 434, name: "Libya"},
    {code: 438, name: "Liechtenstein"},
    {code: 440, name: "Lithuania"},
    {code: 442, name: "Luxembourg"},
    {code: 446, name: "Macao"},
    {code: 450, name: "Madagascar"},
    {code: 454, name: "Malawi"},
    {code: 458, name: "Malaysia"},
    {code: 462, name: "Maldives"},
    {code: 466, name: "Mali"},
    {code: 470, name: "Malta"},
    {code: 584, name: "Marshall Islands"},
    {code: 474, name: "Martinique"},
    {code: 478, name: "Mauritania"},
    {code: 480, name: "Mauritius"},
    {code: 175, name: "Mayotte"},
    {code: 484, name: "Mexico"},
    {code: 498, name: "Moldova"},
    {code: 492, name: "Monaco"},
    {code: 496, name: "Mongolia"},
    {code: 499, name: "Montenegro"},
    {code: 500, name: "Montserrat"},
    {code: 504, name: "Morocco"},
    {code: 508, name: "Mozambique"},
    {code: 104, name: "Myanmar"},
    {code: 516, name: "Namibia"},
    {code: 520, name: "Nauru"},
    {code: 524, name: "Nepal"},
    {code: 528, name: "Netherlands"},
    {code: 540, name: "New Caledonia"},
    {code: 554, name: "New Zealand"},
    {code: 558, name: "Nicaragua"},
    {code: 562, name: "Niger"},
    {code: 566, name: "Nigeria"},
    {code: 570, name: "Niue"},
    {code: 574, name: "Norfolk Island"},
    {code: 580, name: "Northern Mariana Islands"},
    {code: 578, name: "Norway"},
    {code: 512, name: "Oman"},
    {code: 586, name: "Pakistan"},
    {code: 585, name: "Palau"},
    {code: 275, name: "Palestine"},
    {code: 591, name: "Panama"},
    {code: 598, name: "Papua New Guinea"},
    {code: 600, name: "Paraguay"},
    {code: 604, name: "Peru"},
    {code: 608, name: "Philippines"},
    {code: 612, name: "Pitcairn"},
    {code: 616, name: "Poland"},
    {code: 620, name: "Portugal"},
    {code: 630, name: "Puerto Rico"},
    {code: 634, name: "Qatar"},
    {code: 638, name: "Réunion"},
    {code: 642, name: "Romania"},
    {code: 643, name: "Russian Federation"},
    {code: 646, name: "Rwanda"},
    {code: 652, name: "Saint Barthélemy"},
    {code: 659, name: "Saint Kitts and Nevis"},
    {code: 662, name: "Saint Lucia"},
    {code: 666, name: "Saint Pierre and Miquelon"},
    {code: 670, name: "Saint Vincent and the Grenadines"},
    {code: 882, name: "Samoa"},
    {code: 674, name: "San Marino"},
    {code: 678, name: "Sao Tome and Principe"},
    {code: 682, name: "Saudi Arabia"},
    {code: 686, name: "Senegal"},
    {code: 688, name: "Serbia"},
    {code: 690, name: "Seychelles"},
    {code: 694, name: "Sierra Leone"},
    {code: 702, name: "Singapore"},
    {code: 535, name: "Sint Eustatius and Saba Bonaire"},
    {code: 534, name: "Sint Maarten"},
    {code: 703, name: "Slovakia"},
    {code: 705, name: "Slovenia"},
    {code: 90, name: "Solomon Islands"},
    {code: 706, name: "Somalia"},
    {code: 710, name: "South Africa"},
    {code: 239, name: "South Georgia and the South Sandwich Islands"},
    {code: 728, name: "South Sudan"},
    {code: 724, name: "Spain"},
    {code: 144, name: "Sri Lanka"},
    {code: 729, name: "Sudan"},
    {code: 740, name: "Suriname"},
    {code: 744, name: "Svalbard and Jan Mayen"},
    {code: 748, name: "Swaziland"},
    {code: 752, name: "Sweden"},
    {code: 756, name: "Switzerland"},
    {code: 760, name: "Syrian Arab Republic"},
    {code: 158, name: "Taiwan"},
    {code: 762, name: "Tajikistan"},
    {code: 834, name: "Tanzania"},
    {code: 764, name: "Thailand"},
    {code: 180, name: "The Democratic Republic of The Congo"},
    {code: 807, name: "The Former Yugoslav Republic of Macedonia"},
    {code: 626, name: "Timor-Leste"},
    {code: 768, name: "Togo"},
    {code: 772, name: "Tokelau"},
    {code: 776, name: "Tonga"},
    {code: 780, name: "Trinidad and Tobago"},
    {code: 788, name: "Tunisia"},
    {code: 792, name: "Turkey"},
    {code: 795, name: "Turkmenistan"},
    {code: 796, name: "Turks and Caicos Islands"},
    {code: 798, name: "Tuvalu"},
    {code: 800, name: "Uganda"},
    {code: 804, name: "Ukraine"},
    {code: 784, name: "United Arab Emirates"},
    {code: 826, name: "United Kingdom"},
    {code: 581, name: "United States Minor Outlying Islands"},
    {code: 840, name: "United States of America"},
    {code: 858, name: "Uruguay"},
    {code: 860, name: "Uzbekistan"},
    {code: 548, name: "Vanuatu"},
    {code: 862, name: "Venezuela"},
    {code: 704, name: "Viet Nam"},
    {code: 92, name: "Virgin Islands (British)"},
    {code: 850, name: "Virgin Islands (US)"},
    {code: 876, name: "Wallis and Futuna Islands"},
    {code: 732, name: "Western Sahara"},
    {code: 887, name: "Yemen"},
    {code: 894, name: "Zambia"},
    {code: 716, name: "Zimbabwe"}
  ],
  ar: [
    {code: 231, name: "إثيوبيا"},
    {code: 31, name: "أذربيجان"},
    {code: 51, name: "أرمينيا"},
    {code: 533, name: "أروبا"},
    {code: 232, name: "إريتريا"},
    {code: 724, name: "إسبانيا"},
    {code: 36, name: "أستراليا"},
    {code: 233, name: "إستونيا"},
    {code: 376, name: "إسرائيل"},
    {code: 4, name: "أفغانستان"},
    {code: 86, name: "إقليم المحيط البريطاني الهندي"},
    {code: 32, name: "الأرجنتين"},
    {code: 400, name: "الأردن"},
    {code: 304, name: "الأرض الخضراء"},
    {code: 218, name: "الإكوادور"},
    {code: 784, name: "الإمارات العربية المتحدة"},
    {code: 8, name: "ألبانيا"},
    {code: 48, name: "البحرين"},
    {code: 76, name: "البرازيل"},
    {code: 620, name: "البرتغال"},
    {code: 44, name: "البهاما"},
    {code: 70, name: "البوسنة والهرسك"},
    {code: 203, name: "التشيك"},
    {code: 499, name: "الجبل الأسود"},
    {code: 12, name: "الجزائر"},
    {code: 208, name: "الدنمارك"},
    {code: 132, name: "الرأس الأخضر"},
    {code: 682, name: "السعودية"},
    {code: 222, name: "السلفادور"},
    {code: 686, name: "السنغال"},
    {code: 729, name: "السودان"},
    {code: 752, name: "السويد"},
    {code: 732, name: "الصحراء الغربية"},
    {code: 654, name: "الصعود وتريستان دا كونها سانت هيلانة"},
    {code: 706, name: "الصومال"},
    {code: 156, name: "الصين"},
    {code: 368, name: "العراق"},
    {code: 266, name: "الغابون"},
    {code: 608, name: "الفلبين"},
    {code: 10, name: "القطب الجنوبي"},
    {code: 120, name: "الكاميرون"},
    {code: 336, name: "الكرسي الرسولي"},
    {code: 414, name: "الكويت"},
    {code: 276, name: "ألمانيا"},
    {code: 348, name: "المجر"},
    {code: 504, name: "المغرب"},
    {code: 484, name: "المكسيك"},
    {code: 826, name: "المملكة المتحدة"},
    {code: 260, name: "المناطق الجنوبية لفرنسا"},
    {code: 578, name: "النرويج"},
    {code: 40, name: "النمسا"},
    {code: 562, name: "النيجر"},
    {code: 356, name: "الهند"},
    {code: 840, name: "الولايات المتحدة"},
    {code: 392, name: "اليابان"},
    {code: 887, name: "اليمن"},
    {code: 292, name: "اليونان"},
    {code: 28, name: "أنتيغوا وباربودا"},
    {code: 20, name: "أندورا"},
    {code: 360, name: "إندونيسيا"},
    {code: 24, name: "أنغولا"},
    {code: 660, name: "أنغيلا"},
    {code: 858, name: "أوروغواي"},
    {code: 860, name: "أوزبكستان"},
    {code: 800, name: "أوغندا"},
    {code: 804, name: "أوكرانيا"},
    {code: 364, name: "إيران"},
    {code: 352, name: "آيسلندا"},
    {code: 380, name: "إيطاليا"},
    {code: 598, name: "بابوا غينيا الجديدة"},
    {code: 600, name: "باراغواي"},
    {code: 52, name: "باربادوس"},
    {code: 586, name: "باكستان"},
    {code: 585, name: "بالاو"},
    {code: 60, name: "برمودا"},
    {code: 96, name: "بروناي"},
    {code: 56, name: "بلجيكا"},
    {code: 100, name: "بلغاريا"},
    {code: 84, name: "بليز"},
    {code: 50, name: "بنغلاديش"},
    {code: 591, name: "بنما"},
    {code: 204, name: "بنين"},
    {code: 64, name: "بوتان"},
    {code: 72, name: "بوتسوانا"},
    {code: 630, name: "بورتوريكو"},
    {code: 854, name: "بوركينا فاسو"},
    {code: 104, name: "بورما"},
    {code: 108, name: "بوروندي"},
    {code: 616, name: "بولندا"},
    {code: 68, name: "بوليفيا"},
    {code: 258, name: "بولينيزيا الفرنسية"},
    {code: 612, name: "بيتكيرن"},
    {code: 604, name: "بيرو"},
    {code: 112, name: "بيلاروسيا"},
    {code: 764, name: "تايلاند"},
    {code: 158, name: "تايوان"},
    {code: 795, name: "تركمانستان"},
    {code: 792, name: "تركيا"},
    {code: 780, name: "ترينيداد وتوباغو"},
    {code: 148, name: "تشاد"},
    {code: 152, name: "تشيلي"},
    {code: 834, name: "تنزانيا"},
    {code: 768, name: "توغو"},
    {code: 798, name: "توفالو"},
    {code: 772, name: "توكيلاو"},
    {code: 776, name: "تونجا"},
    {code: 788, name: "تونس"},
    {code: 626, name: "تيمور ليشتي"},
    {code: 388, name: "جامايكا"},
    {code: 300, name: "جرينادا"},
    {code: 248, name: "جزر آلاند"},
    {code: 92, name: "جزر العذراء (البريطانية)"},
    {code: 850, name: "جزر العذراء (الولايات المتحدة)"},
    {code: 174, name: "جزر القمر"},
    {code: 462, name: "جزر المالديف"},
    {code: 581, name: "جزر الولايات المتحدة البعيدة الصغرى"},
    {code: 796, name: "جزر تركس وكايكوس"},
    {code: 90, name: "جزر سليمان"},
    {code: 234, name: "جزر صناعية"},
    {code: 238, name: "جزر فوكلاند"},
    {code: 136, name: "جزر كايمان"},
    {code: 184, name: "جزر كوك"},
    {code: 166, name: "جزر كوكوس"},
    {code: 584, name: "جزر مارشال"},
    {code: 580, name: "جزر مريانا الشمالية"},
    {code: 162, name: "جزيرة الكريسماس"},
    {code: 833, name: "جزيرة آيل أوف مان"},
    {code: 74, name: "جزيرة بوفيت"},
    {code: 574, name: "جزيرة نورفولك"},
    {code: 638, name: "جمع شمل"},
    {code: 140, name: "جمهورية أفريقيا الوسطى"},
    {code: 214, name: "جمهورية الدومينيكان"},
    {code: 178, name: "جمهورية الكونغو"},
    {code: 180, name: "جمهورية الكونغو الديمقراطية"},
    {code: 372, name: "جمهورية أيرلندا"},
    {code: 807, name: "جمهورية مقدونيا"},
    {code: 710, name: "جنوب أفريقيا"},
    {code: 728, name: "جنوب السودان"},
    {code: 312, name: "جوادلوب"},
    {code: 268, name: "جورجيا"},
    {code: 239, name: "جورجيا الجنوبية وجزر ساندويتش الجنوبية"},
    {code: 262, name: "جيبوتي"},
    {code: 832, name: "جيرزي"},
    {code: 212, name: "دومينيكا"},
    {code: 646, name: "رواندا"},
    {code: 643, name: "روسيا"},
    {code: 642, name: "رومانيا"},
    {code: 894, name: "زامبيا"},
    {code: 716, name: "زيمبابوي"},
    {code: 384, name: "ساحل العاج"},
    {code: 882, name: "ساموا"},
    {code: 16, name: "ساموا الأمريكية"},
    {code: 666, name: "سان بيار وميكلون"},
    {code: 674, name: "سان مارينو"},
    {code: 652, name: "سانت بارتيليمي"},
    {code: 670, name: "سانت فنسينت والجرينادينز"},
    {code: 659, name: "سانت كيتس ونيفيس"},
    {code: 662, name: "سانت لوسيا"},
    {code: 534, name: "سانت مارتن"},
    {code: 678, name: "ساو تومي وبرينسيب"},
    {code: 144, name: "سريلانكا"},
    {code: 744, name: "سفالبارد وجان مايان"},
    {code: 512, name: "سلطنة عمان"},
    {code: 703, name: "سلوفاكيا"},
    {code: 705, name: "سلوفينيا"},
    {code: 334, name: "سمعت وجزر ماكدونالد"},
    {code: 702, name: "سنغافورة"},
    {code: 748, name: "سوازيلاند"},
    {code: 760, name: "سوريا"},
    {code: 740, name: "سورينام"},
    {code: 756, name: "سويسرا"},
    {code: 694, name: "سيراليون"},
    {code: 690, name: "سيشيل"},
    {code: 535, name: "سينت أوستاتيوس وسابا بونير"},
    {code: 688, name: "صربيا"},
    {code: 762, name: "طاجيكستان"},
    {code: 270, name: "غامبيا"},
    {code: 288, name: "غانا"},
    {code: 308, name: "غرينادا"},
    {code: 320, name: "غواتيمالا"},
    {code: 316, name: "غوام"},
    {code: 328, name: "غويانا"},
    {code: 254, name: "غيانا الفرنسية"},
    {code: 831, name: "غيرنزي"},
    {code: 324, name: "غينيا"},
    {code: 226, name: "غينيا الاستوائية"},
    {code: 624, name: "غينيا بيساو"},
    {code: 548, name: "فانواتو"},
    {code: 250, name: "فرنسا"},
    {code: 275, name: "فلسطين"},
    {code: 862, name: "فنزويلا"},
    {code: 246, name: "فنلندا"},
    {code: 704, name: "فيتنام"},
    {code: 242, name: "فيجي"},
    {code: 196, name: "قبرص"},
    {code: 417, name: "قرغيزستان"},
    {code: 634, name: "قطر"},
    {code: 398, name: "كازاخستان"},
    {code: 540, name: "كاليدونيا الجديدة"},
    {code: 191, name: "كرواتيا"},
    {code: 116, name: "كمبوديا"},
    {code: 124, name: "كندا"},
    {code: 192, name: "كوبا"},
    {code: 531, name: "كوراساو"},
    {code: 410, name: "كوريا الجنوبية"},
    {code: 408, name: "كوريا الشمالية"},
    {code: 188, name: "كوستاريكا"},
    {code: 170, name: "كولومبيا"},
    {code: 296, name: "كيريباتي"},
    {code: 404, name: "كينيا"},
    {code: 428, name: "لاتفيا"},
    {code: 418, name: "لاوس"},
    {code: 422, name: "لبنان"},
    {code: 442, name: "لوكسمبورغ"},
    {code: 434, name: "ليبيا"},
    {code: 430, name: "ليبيريا"},
    {code: 440, name: "ليتوانيا"},
    {code: 438, name: "ليختنشتاين"},
    {code: 426, name: "ليسوتو"},
    {code: 474, name: "مارتينيك"},
    {code: 446, name: "ماكاو"},
    {code: 454, name: "مالاوي"},
    {code: 470, name: "مالطا"},
    {code: 466, name: "مالي"},
    {code: 458, name: "ماليزيا"},
    {code: 583, name: "مايكرونيزيا"},
    {code: 175, name: "مايوت"},
    {code: 450, name: "مدغشقر"},
    {code: 818, name: "مصر"},
    {code: 496, name: "منغوليا"},
    {code: 478, name: "موريتانيا"},
    {code: 480, name: "موريشيوس"},
    {code: 508, name: "موزمبيق"},
    {code: 498, name: "مولدوفا"},
    {code: 492, name: "موناكو"},
    {code: 500, name: "مونتسيرات"},
    {code: 516, name: "ناميبيا"},
    {code: 520, name: "ناورو"},
    {code: 524, name: "نيبال"},
    {code: 566, name: "نيجيريا"},
    {code: 558, name: "نيكاراجوا"},
    {code: 554, name: "نيوزيلندا"},
    {code: 570, name: "نيوي"},
    {code: 332, name: "هايتي"},
    {code: 340, name: "هندوراس"},
    {code: 528, name: "هولندا"},
    {code: 344, name: "هونغ كونغ"},
    {code: 876, name: "واليس وفوتونا"}
  ]
};
