import React, { Component } from 'react';
import closeIcon from "app/resources/assets/header/close.png";
import starIcon from "app/resources/assets/header/star.png";
import logo from 'app/resources/assets/weyyak-logo1.svg';
import './index.scss';
import { isMobile, isAndroid, isIOS } from "react-device-detect";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCloseOpen: true,
      isScrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {

    this.setState({ isScrolled: window.scrollY > 10 });
  }

  toggleClose = () => {
    this.setState(prevState => ({ isCloseOpen: !prevState.isCloseOpen }));
  };

  render() {
    const { isCloseOpen, isScrolled } = this.state;
// console.log(this.state,"Header");
const screenWidth = window.innerWidth;
const isTablet = screenWidth >= 768 && screenWidth <= 1024;
const isMobileView = screenWidth <= 767
    return (
      <header className={`header ${isScrolled ? 'onscroll' : ''}`}>
        {isMobileView && isCloseOpen && (
          <div className="install_app">
            {this.props.children}
            <div className="closeicon" id="app_close_" onClick={this.toggleClose}>
              <img src={closeIcon} alt="Close" />
            </div>
            <div className="alert_logo">
              <img src={logo} alt="Logo" />
            </div>
            <div className="alert_content">
              <p className="alert_title en">Weyyak app</p>
              <p className="alert_title ar">تطبيق وياك</p>
              <p className="alert_sbtitle en">Weyyak app</p>
              <p className="alert_sbtitle ar">تطبيق وياك</p>
              <div className="alert_star">
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
              </div>
              {isIOS ? (
                <div>
                  <p className="get_app en">GET— On the App Store</p>
                  <p className="get_app ar">احصل عليه من App Store</p>
                </div>
              ) : (
                <div>
                  <p className="get_app_play en">Get it on Play Store</p>
                  <p className="get_app_play ar">احصل عليه من Play Store</p>
                </div>
              )}
            </div>
            {isAndroid ? (
              <div>
                <div className="alert_view eninstall">
                  <a href="https://play.google.com/store/apps/details?id=com.tva.z5" onClick={() => window.ga('send', 'event', 'App Installations', 'download-app-bar-clicked', 'play store', 'Click', 'Install')}>Install</a>
                </div>
                <div className="alert_view arinstall">
                  <a href="https://play.google.com/store/apps/details?id=com.tva.z5" onClick={() => window.ga('send', 'event', 'App Installations', 'download-app-bar-clicked', 'play store', 'Click', 'Install')}>حمّله الآن</a>
                </div>
              </div>
            ) : (
              <div>
                <div className="alert_view eninstall">
                  <a href="https://apps.apple.com/in/app/z5-weyyak-%D9%88%D9%8A%D8%A7%D9%83/id1226514781" onClick={() => window.ga('send', 'event', 'App Installations', 'download-app-bar-clicked', 'app store', 'Click', 'Install')}>Install</a>
                </div>
                <div className="alert_view arinstall">
                  <a href="https://apps.apple.com/in/app/z5-weyyak-%D9%88%D9%8A%D8%A7%D9%83/id1226514781" onClick={() => window.ga('send', 'event', 'App Installations', 'download-app-bar-clicked', 'app store', 'Click', 'Install')}>حمّله الآن</a>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="header_bottom">
          <div className="contentMiddle">
            {this.props.contentMiddle}
          </div>
          <div className="contentRight">
            {this.props.contentRight}
          </div>
          <div className="contentLeft">
            {this.props.contentLeft}
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
